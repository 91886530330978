import React from "react";
import styled from "styled-components";

const HomeFeatures = ({ realWork }) => {
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyleWrapper>
      <div className="container-fluid py-5">
        <div className="feature padding-left-8 padding-right-8">
          <h1 className="title text-center">{realWork.title}</h1>
          <p className="text-center subtext">{realWork.description}</p>
        </div>
        <div className="row itworks padding-left-8 padding-right-8 py-4">
          {realWork.RealWorkTypes.length > 0 &&
            realWork.RealWorkTypes.map(item => (
              <div className="itworks-left col-sm-6 col-12 d-flex padding-left-0 padding-right-6 py-sm-4 py-3">
                {item.logo && (
                  <img className="realWorkLogo" src={mainUrl + item.logo.url} />
                )}
                <h2 className="subheading">{item.logoTitle}</h2>
              </div>
            ))}
        </div>
      </div>
    </StyleWrapper>
  );
};

export default HomeFeatures;

const StyleWrapper = styled.div`
  .itworks {
    width: 100%;
    margin: 0 auto;
    padding: 3rem;
  }
  .itworks-left {
    display: flex !important;
    align-items: center;
  }
  .feature {
    margin-top: 95px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .realWorkLogo {
    height: 50px;
    object-fit: cover;
    padding-left: 3rem;
    transform: scale(0.8);
  }

  .subheading {
    padding: 0 auto;
    padding-left: 1rem;
  }
  .subtext {
  }
  @media (max-width: 1000px) {
    .realWorkLogo {
      padding-left: 0rem;
    }
    .subheading {
      padding: 0rem;
    }
  }
  @media (min-width: 991px) {
    .title {
      padding-bottom: 20px;
    }

    .realWorkLogo {
      height: 80px;
      object-fit: cover;
    }
    .subheading {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 992px) {
    .subheading {
      font-size: 20px;
      line-height: 30px;
      margin-left: 1rem;
    }
    .feature {
      margin-top: 10px !important;
    }
  }
  @media screen and (max-width: 768px) {
    .subheading {
      font-size: 15px;
    }
  }
`;
