import React from "react";
import Layout from "../components/layout";
import MainBlock from "../components/MainBlock";
import HomeFeatures from "../components/HomeFeatures";
import LogosBlock from "../components/LogosBlock";
import StatisticBlock from "../components/StatisticBlock";
import RoadMap from "../components/RoadMap";
import SEO from "../components/seo";
import AboutUpreach from "../components/AboutUpreach";
import axios from "axios";
import { useState, useEffect } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Loader";
import HomeHeader from "../components/HomerHeader";
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Home />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/real-homepage`);
};
const Home = () => {
  const [newData, setNewData] = useState(null);
  const { data: pageData } = useQuery("realRating", fetchData);
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
  }, [pageData]);
  return (
    <Layout>
      {newData === null && <Loader />}
      {newData && <SEO title={newData.SEOtitle} />}
      {newData && newData.ShowHeaderAndSubHeader && (
        <HomeHeader header={newData.Header} subHeader={newData.SubHeader} />
      )}
      <div id="mainBlock-section"> </div>
      {newData && newData.showRealWorks && newData.RealWorks && (
        <HomeFeatures realWork={newData.RealWorks} />
      )}
      {newData && newData.showMainBlock && (
        <MainBlock
          heading={newData.WhyUseRealratingTitle}
          student={newData.Students}
          employer={newData.Employers}
        />
      )}
      {newData && newData.showStats && newData.stats.length > 0 && (
        <StatisticBlock stats={newData.stats} />
      )}
      {newData && newData.showRoadMap && newData.RealRoadMap && (
        <RoadMap roadMap={newData.RealRoadMap} />
      )}
      {newData && newData.ShowAboutUpreach && (
        <AboutUpreach
          heading={newData.AboutUpreachtitle}
          content={newData.aboutUpreach}
          image={newData.AboutUpreachImage}
        />
      )}
    </Layout>
  );
};
