import React from "react";
import styled from "styled-components";
import downArrow from "../images/downArrow.png";
const StyledWrapper = styled.div`
  .heading-section {
    background-color: #ffffff;
  }
  .heading {
    text-align: center;
    padding-top: 140px;
    padding-bottom: 120px;
  }
  .header {
    padding-bottom: 40px;
    font-size: 65px;
    /* transform: scale(1.6); */
  }
  .sub-header {
    width: 50%;
    font-size: 18px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .line {
    text-align: center;
    display: inline;
    text-align: center;
    display: inline-block;
    width: 250px;
    margin-top: 3rem;
    color: grey;
  }

  .img-class {
    margin-top: 90px !important;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  @media screen and (max-width: 1246px) {
    .header {
      font-size: 60px;
    }
  }
  @media screen and (max-width:1150px){
    .header {
      font-size: 50px;
    }
  }
  @media screen and (max-width: 1024px) {
    .header {
      font-size: 50px;
    }
  }
  @media screen and (max-width: 992px) {
    .header {
      font-size: 40px;
    }
    .sub-header {
      width: 70%;
    }
  }
  @media screen and (max-width: 700px) {
    .header {
      font-size: 30px;
      font-size: 50px;
    }
  }
  @media screen and (max-width: 556px) {
    .header {
      font-size: 18px;
    }
    .sub-header {
      font-size: 15px;
    }
    .heading {
    padding-top: 60px;
    padding-bottom: 55px;
    }
    .img-class{
    margin-top: 50px !important;
    height: 90px;
    }
  }
`;
const handleClick = () => {
  setTimeout(() => {
    document.querySelector("#mainBlock-section").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }, 300);
};

const HomeHeader = ({ header, subHeader }) => {
  return (
    <StyledWrapper>
      <div className="heading-section">
        <div className="heading">
          <h1 className="header">{header}</h1>
          <p className="text-center sub-header">{subHeader}</p>
          <hr className="line" />
          <img
            className="img-class d-block"
            onClick={() => {
              handleClick();
            }}
            src={downArrow}
          />
        </div>
      </div>
    </StyledWrapper>
  );
};
export default HomeHeader;
