import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  .upreach-section {
    padding: 60px;
  }
  .row-section {
    margin-top: 3rem;
  }
  .heading {
    text-align: center;
    font-size: 32px;
    font-weight: 900;
    line-height: 43px;
    margin-bottom: 1.5rem;
  }
  li {
    margin-top: 16px;
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
  }

  .flatButton {
    background: #e43d5a;
    color: white;
    padding: 18px;
    margin-top: 40px;
  }
  .img-class {
    height: 75px;
    object-fit: contain;
  }
  .column-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inner-text {
    text-align: justify;
    text-justify: inter-word;
  }
  @media screen and (max-width: 556px) {
    .subtext {
      margin-bottom: 67px;
    }
    .upreach-section {
      padding: 40px;
    }
    .img-class {
      height: 60px;
    }
  }
`;

const AboutUpreach = ({ heading, content, image }) => {
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyledWrapper>
      <div className="upreach-section">
        <h1 className="heading">{heading}</h1>
        <div className="row row-section">
          <div className="col-12 col-sm-8 col-md-7 col-lg-7">
            <div className="subtext">
              <div>
                {content.length > 0 &&
                  React.Children.toArray(
                    content.map(item => (
                      <p className="inner_text">{item.content}</p>
                    ))
                  )}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4 col-md-5 col-lg-5 column-2">
            {image && (
              <img className="img-fluid img-class" src={mainUrl + image.url} />
            )}
          </div>
        </div>
        <div className="col-sm-12 col-12 d-flex justify-content-center align-items-center">
          <a
            className="flatButton btn btn-default"
            target="_blank"
            href="https://upreach.org.uk/"
          >
            Find out more
          </a>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default AboutUpreach;
