import styled from "styled-components";
import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const StyledWrapper = styled.div`
  .random-background {
    padding: ${props => props.theme["percent-10"]} 0
      ${props => props.theme["percent-10"]} 0;

    @media (min-width: 994px) and (max-width: 1120px) {
      min-width: 285px;
    }
  }

  .random-background {
    border: 2px solid #e43d5a;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 0 auto; */
  }
  .statistic-text {
    color: black;
    text-align: center;
  }
  .statistic-number {
    color: black;
    display: inherit;
  }
  [class^="col"] {
    display: flex;
    flex-direction: column;
  }

  [class^="col"] div {
    flex-grow: 1;
  }
`;

const StatisticBox = ({ stats }) => {
  const [focus, setFocus] = React.useState(false);

  return (
    <StyledWrapper>
      <div className="container-fluid padding-left-8 padding-right-8 py-5">
        <h1>{stats.title}</h1>
        <div className="row">
          {React.Children.toArray(
            stats.map(item => (
              <div className="col-12 col-md-6 col-lg-4 mt-4">
                <div
                  className="random-background d-flex justify-content-center padding-left-8 padding-right-8"
                  // style={{ backgroundColor: `${item.color}` }}
                >
                  <div>
                    <CountUp
                      duration={2}
                      end={item.number !== null ? item.number : "-"}
                      start={focus ? 1 : null}
                      suffix={item.suffix}
                      prefix={item.prefix}
                      separator=","
                      decimals={item.decimals}
                    >
                      {({ countUpRef }) => (
                        <VisibilitySensor
                          onChange={isVisible => {
                            if (isVisible) {
                              setFocus(true);
                            }
                          }}
                          delayedCall
                        >
                          <span
                            ref={countUpRef}
                            className="text-center statistic-number"
                          />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <p className="statistic-text">{item.description}</p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </StyledWrapper>
  );
};

export default StatisticBox;
