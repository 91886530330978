import React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import capitalizeFirstLetter from "../utils/capital";

import videoURL from "../images/animation.mp4";

const RoadMap = ({ roadMap }) => {
  useEffect(() => {
    var v = document.getElementById("Test_Video");
    v.playbackRate = 0.75;
  }, []);
  return (
    <StyleWrapper>
      <div className="container-fluid py-5 white-background">
        <div className="video-container">
          <h1 className="text-center title pb-sm-5">{roadMap.title}</h1>
          {/* <div className="row"> */}
          <video
            id="Test_Video"
            className="video-animation"
            width="400"
            autoPlay
            muted
          >
            <source src={videoURL} type="video/mp4" />
            Your Browser does not support HTML video.
          </video>
        </div>
      </div>
    </StyleWrapper>
  );
};

export default RoadMap;

const StyleWrapper = styled.div`
  .video-animation {
    width: 100%;
    padding: 0;
    /* transform: scale(1.1); */
  }
  /* .video-container {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  } */
  .image {
    width: 300px;
    object-fit: cover;
  }

  @media (min-width: 991px) {
    .image {
      width: 1200px;
    }
  }

  .circle {
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 50%;
    font-size: 24px;
    color: #fff;
    text-align: center;
    background: #e43d5a;
    display: inline-block;
  }

  .subtext {
    padding-top: 30px;
    font-weight: 600;
  }

  .line {
    border-bottom: 2px dashed #304563;
    width: 100%;
  }
 
`;
