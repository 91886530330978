import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import capitalizeFirstLetter from "../utils/capital";
import students from "../images/students.png";
import employee from "../images/employe.png";
const MainBlock = ({ student, employer, heading }) => {
  return (
    <StyleWrapper>
      <div className="container-fluid white-background main-block  border-top py-4 py-sm-0">
        <h1 className="text-center">{heading}</h1>
        <div className="row firstset padding-left-2 padding-right-2">
          {student && (
            <div className="col-sm-6 col-12 block1">
              {student && (
                <h1 className="title pb-sm-4">
                  {student.title} <img className="img-class" src={students} />
                </h1>
              )}
              <ReactMarkdown className="text-center subtext">
                {student.description}
              </ReactMarkdown>
              <a
                target="_blank"
                className="flatButton btn btn-default d-block d-sm-none"
                href="https://realrating.co.uk/"
              >
                {student.buttonText}
              </a>
            </div>
          )}

          {employer && (
            <div className="col-sm-6 col-12 block2">
              <h1 className="title pb-sm-4">
                {employer.title}
                <span>
                  <img className="img-class img-class-1" src={employee} />
                </span>
              </h1>

              <ReactMarkdown className=" text-center subtext">
                {employer.description}
              </ReactMarkdown>
              <a
                target="_blank"
                className="flatButton btn btn-default d-block d-sm-none"
                href="https://upreach-realrating.web.app/employers"
              >
                {employer.buttonText}
              </a>
            </div>
          )}
        </div>
        <div className="d-none d-sm-block">
          <div className="row button-test padding-left-auto padding-right-auto">
            <div className="col-sm-6 col-6 blockButton text-center pb-5">
              {student && (
                <a
                  className="flatButton btn btn-default studentbutton"
                  target="_blank"
                  href="https://realrating.co.uk/"
                >
                  {student.buttonText}
                </a>
              )}
            </div>
            <div className="col-sm-6 col-6 text-center pb-5">
              {employer && (
                <a
                  target="_blank"
                  className="flatButton btn btn-default"
                  href="https://upreach-realrating.web.app/employers"
                >
                  {employer.buttonText}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </StyleWrapper>
  );
};

export default MainBlock;
const StyleWrapper = styled.div`
  .img-class {
    height: 30px;
  }
  .img-class-1 {
    margin-left: 10px;
  }
  .main-block {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .firstset {
    width: 100%;
    margin: 0 auto;
    display: flex;
  }
  .button-test {
    margin: 0 auto;
    padding: 0rem;
  }
  .title {
    text-align: center;
  }
  .subtext {
    margin: 0 auto;
    padding: 1rem;
  }
  .title-2 {
    margin-left: 2rem;
  }
  .subtext-2 {
    margin-left: 2rem;
  }
  .button-align-1 {
    margin: 30rem;
  }
  .flatButton {
    background: #e43d5a;
    color: white;
    padding: 18px;
  }

  .block1,
  .block2 {
    padding: 40px;
  }

  @media (min-width: 991px) {
    .subtext {
      width: 90%;
    }

    .block1,
    .block2 {
      padding: 80px 50px 20px;
    }
    .flatButton {
      margin: 0px 40px;
    }
  }
  @media screen and (max-width:556px){
    .block1{
      padding: 10px;
    }
    .block2{
      padding: 10px;
    }
  }
`;
